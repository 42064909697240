import React from 'react'
import Layout from '../layouts/Layout'
import menu0 from '../images/menu_0.jpeg'
import drinkmenu1 from '../images/drinkmenu1-1.jpg'
import drinkmenu2 from '../images/drinkmenu1-2.jpg'
import takeaway1 from '../images/takeaway_1.jpeg'
import takeaway2 from '../images/takeaway_2.jpeg'
import takeaway3 from '../images/takeaway_3.jpeg'
import SEO from '../components/SEO'

const Menu = () => {
  return (
    <Layout>
      <h1 className="text-4xl text-center mb-10 font-black">メニュー</h1>
      <div className="bg-white p-6 rounded-md text-xl">
        メニューについて
        <br /> インスタグラムにて随時更新しております
        <br />
        <a
          href="https://www.instagram.com/ashibi1040"
          className="underline"
          target="_blank"
        >
          https://www.instagram.com/ashibi1040
        </a>
      </div>
    </Layout>
  )
  return (
    <Layout>
      <div className="flex flex-col space-y-5">
        <div className="bg-white p-6 rounded-md">
          <h2 className="text-2xl font-semibold mb-4">とんかつメニュー</h2>
          <img src={menu0} alt="ashibi-menu0" />
        </div>
        <div className="bg-white p-6 rounded-md">
          <h2 className="text-2xl font-semibold mb-4">ドリンクメニュー</h2>
          <div className="grid grid-cols-1 lg:grid-cols-2">
            <img src={drinkmenu1} alt="ashibi-drink" />
            <img src={drinkmenu2} alt="ashibi-drink" />
          </div>
        </div>
        <div className="bg-white p-6 rounded-md">
          <h2 className="text-2xl font-semibold mb-4">テイクアウト メニュー</h2>
          <h3 className="font-semibold text-red-500 mb-3">
            ※密を避けるためにも、お電話でのご予約をお願い致します
          </h3>
          <div className="mb-10">
            <h5 className="font-semibold mb-1">定番メニュー</h5>
            <ul className="list-disc ml-4 mb-5">
              <li className="mb-2">
                ヒレかつ弁当・・・￥２０００（消費税別）
                <br />
                ヒレかつ１２０g・唐揚げ・揚げ餃子・鴨スモーク・香の物・御飯
              </li>
              <li className="mb-2">
                ロースかつ弁当・・・￥２０００（消費税別）
                <br />
                ロースかつ１３０g・唐揚げ・揚げ餃子・鴨スモーク・香の物・御飯
              </li>
              <li>
                盛り合わせ弁当・・・￥２０００（消費税別）
                <br />
                一口ヒレかつ・海老フライ・カニ爪クリームコロッケ・唐揚げ・揚げ餃子・鴨スモーク・香の物・御飯
              </li>
            </ul>
            <h5 className="font-semibold mb-1">特別メニュー</h5>
            <ul className="list-disc ml-5">
              <li className="mb-2">
                大皿盛り合わせ・・・￥８５００（税別） ※４〜５名様分
                <br />
                ひれかつ・ロースかつ・海老フライ・カニ爪クリームコロッケ・唐揚げ・揚げ餃子
              </li>
              <li>
                とんかつ弁当（平日限定）・・・￥１０００（消費税別）
                <br />
                ロースかつ１２０g香の物・御飯
                <br />
                ※平日ランチランチタイム限定でお造り致します（電話にてご予約をお願い致します）
              </li>
            </ul>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
            <img className="rounded-md" src={takeaway1} alt="ashibi-takeaway" />
            <img className="rounded-md" src={takeaway2} alt="ashibi-takeaway" />
            <img className="rounded-md" src={takeaway3} alt="ashibi-takeaway" />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const Head = () => (
  <SEO title="メニュー｜とんかつの店 馬酔木 横浜市都筑区" />
)

export default Menu
